.lectura {
  height: calc(100vh - var(--bar-height));
  position: absolute;
  top: var(--bar-height);

  .qr-reader--scanner {
    width: 100%;
    min-width: 40vw;
    max-width: 400px;
    border: 1px solid red;
  }

  .qr--texto {
    font-size: 2em;
  }

  .qr--icon {
    font-size: 5em;
  }

  .qr--arrow {
    font-size: 2em;
  }

  .qr--info {
    // min-width: 40vw;
  }

  .qr--version {
    font-size: 0.5em;
  }

  .check {
    height: 50px;
    width: 50px;
    color: green;
  }

  .cancel {
    height: 50px;
    width: 50px;
    color: red;
  }

  .dialog {
    height: 100vh;
  }

}

